<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>我发起的</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="send-info">
            <div class="send-condition flex-center">
                <div class="send-condition-item">
                    <el-select
                            v-model="type"
                            placeholder="请选择发起类型"
                            @change="classifyChange($event, 0)"
                    >
                        <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="send-condition-item">
                    <el-select
                            v-model="classify"
                            placeholder="请选择分类"
                            clearable
                            @change="classifyChange($event, 1)"
                    >
                        <el-option
                                v-for="item in classifyOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="send-condition-state flex-center">
                    <span :class="{ active: state === 0 }" @click="state = 0"
                    >待办</span
                    >
                    <span :class="{ active: state === 3 }" @click="state = 3">全部</span>
                    <span :class="{ active: state === 1 }" @click="state = 1"
                    >已完成</span
                    >
                    <span :class="{ active: state === 4 }" @click="state = 4"
                    >进行中</span
                    >
                    <span :class="{ active: state === 2 }" @click="state = 2"
                    >已拒绝</span
                    >

                </div>
            </div>
            <div class="send-list">
                <el-table
                        :data="tableData"
                        header-row-class-name="table-header"
                        @row-click="toDetail"
                        style="width: 100%"
                >
                    <el-table-column prop="title" label="审批标题" width="240">
                        <template slot-scope="scope">
                            <div class="table-title flex-center">
                <span>{{
                    scope.row.form_name.substring(
                        scope.row.form_name.length - 2,
                        scope.row.form_name.length
                    )
                    }}</span>
                                <p>
                                    <b>{{ scope.row.form_name }}</b
                                    >提交的<b>{{ scope.row.type | typeFilter }}</b>
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="审核摘要">
                        <template slot-scope="scope">
                            <div
                                    v-html="scope.row.desc"
                                    class="table-desc"
                            ></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="addtime" label="发起时间" width="180">
                        <template slot-scope="scope">
                            <div
                                    class="table-addtime"
                            >
                                {{ scope.row.add_time }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endtime" label="完成时间" width="180">
                        <template slot-scope="scope">
                            <div
                                    class="table-endtime"
                            >
                                {{ scope.row.sp_time }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="state" label="状态" width="200">
                        <template slot-scope="scope">
                            <div class="table-state">
                                <p :class="{
                    replied : state ===0 && ((scope.row.is_replied === 0 && Number(scope.row.type)===3) || (scope.row.contract === 0 &&Number(scope.row.type)===6)),
                    active: scope.row.state_code === 3 || scope.row.state==='审批拒绝',
                    green:scope.row.state_code === 2 && Number(scope.row.type) == 5,
                  }">
                                    {{ scope.row.state }}
                                </p>
                               <el-badge is-dot :hidden="scope.row.is_read === 1" v-if="scope.row.is_read === 0">

                               </el-badge>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        class="page-all"
                        background
                        layout="prev, pager, next"
                        :page-size="limit"
                        :total="pageTotal"
                        :current-page.sync="page"
                        @current-change="setPage"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {getApprovalType, toDetail} from "../../tools";

    export default {
        name: "send",
        data() {
            return {
                //审批类型 0全部 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
                typeOptions: this.$tools.approvalClassify(),
                classifyOptions: [],
                classify: "",
                type: "",
                lvl: "",
                //0待审批 1已通过 2已拒绝 3全部 4进行中
                state: 0,
                tableData: [],
                page: 1,
                pageTotal: 1,
                limit: 6,
            };
        },
        activated() {
            this.setParms();
            this.init();
        },
        methods: {
            setParms(){
                this.$emit("setIndex", [0, 2]);
            },
            //  修该筛选
            classifyChange(event, view) {
                if (view === 0) {
                    this.type = event;
                } else if (view === 1) {
                    this.classify = event;
                }
                this.page = 1;
                this.init();
            },
            // 分页变化
            setPage(val) {
                this.page = val;
                this.init();
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .myInitiate({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        page: this.page,
                        limit: this.limit,
                        type: this.type ? this.type : 0,
                        pull: (this.type === 4 || this.type === 3 || this.type === 6 || this.type === 9 || this.type === 17 ) && this.classify !== '' ? this.classify : 3,
                        level: this.lvl ? this.lvl : 0,
                        state: this.state,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                page: this.page,
                                limit: this.limit,
                                pull: (this.type === 4 || this.type === 3 || this.type === 6 || this.type === 9 || this.type === 17) && this.classify !== '' ? this.classify : 3,
                                type: this.type ? this.type : 0,
                                level: this.lvl ? this.lvl : 0,
                                state: this.state,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.list.map((item) => {
                            item.add_time = this.$tools.ZHDate(
                                new Date(item.add_time * 1000),
                                1
                            );
                            item.sp_time = item.sp_time
                                ? this.$tools.ZHDate(new Date(item.sp_time * 1000), 1)
                                : "";
                        });
                        this.tableData = res.data.list;
                        this.pageTotal = res.data.count;
                    })
                    .catch(() => {
                        this.tableData = [];
                        this.pageTotal = 0
                    });
            },
            /*去项目详情*/
            toDetail(row) {
                toDetail(row,1)
            },
            /*修改项目优先级*/
            setState(command) {
                this.$api.apiContent
                    .setLevel({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: command[1],
                        level: command[0],
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                id: command[1],
                                level: command[0],
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then(() => {
                        this.$message.success("修改成功");
                        this.init();
                    })
                    .catch((e) => {
                        this.$message.error(e.msg);
                    });
            },
        },
        watch: {
            state() {
                this.page = 1;
                this.init();
            },
            type() {
                if (this.type === 3 || this.type === 6 || this.type === 17) {
                    this.classifyOptions = [{value: 1, label: "已回",}, {value: 0, label: "未回",}]
                } else if (this.type === 4) {
                    this.classifyOptions = [{value: 1, label: "全部到款",}, {value: 0, label: "部分到款",}]
                } else if (this.type === 9) {
                    this.classifyOptions = [{value: 0, label: "全部",}, {value: 1, label: "资料补充中",}]
                } else {
                    this.classifyOptions = []
                }
                this.classify = ''
            }
        },
        filters: {
            typeFilter(type) {
                // 1行政审批 2招商资料 3赞助协议 4到款 5发票 6下游合同 7特殊付款 8结项
               return getApprovalType(type)
            },
        },

    };
</script>

<style scoped lang="scss">

    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }

        .send-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;

            .send-condition {
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .send-condition-item {
                    margin-right: 20px;
                }

                .send-condition-state {
                    border: 1px solid #3b77e7;
                    border-radius: 4px;
                    overflow: hidden;

                    span {
                        display: block;
                        width: 120px;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        font-size: 16px;
                        border-right: 1px solid #3b77e7;
                        cursor: pointer;

                        &:last-child {
                            border-right: 0;
                        }

                        &.active {
                            background: #3b77e7;
                            color: #fff;
                        }
                    }
                }
            }

            .send-list {
                padding: 34px 28px;
                min-height: 600px;

                .table-title {
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        width: 56px;
                        height: 56px;
                        border-radius: 56px;
                        text-align: center;
                        /*line-height: 24px;*/
                        font-size: 18px;
                        color: #fff;
                        background-color: #3b77e7;
                    }

                    p {
                        flex: 1;
                        margin-left: 10px;
                        font-size: 16px;
                        color: #000;

                        &.active {
                            color: #f03737;

                            b {
                                color: #f03737;
                            }
                        }

                        b {
                            color: #3b77e7;
                            font-weight: normal;
                        }
                    }
                }

                .table-desc {
                    text-align: left;
                    font-size: 16px;
                    color: #666;
                    padding-left: 20px;

                    &.active {
                        color: #f03737;
                    }
                }

                .table-addtime {
                    text-align: center;
                    font-size: 16px;
                    color: #666;

                    &.active {
                        color: #f03737;
                    }
                }

                .table-endtime {
                    text-align: center;
                    font-size: 16px;
                    color: #666;

                    &.active {
                        color: #f03737;
                    }
                }

                .table-state {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    text-align: center;
                    font-size: 16px;
                    position: relative;

                    &.active2 {
                        background: url("../../assets/img/level2.png") no-repeat left center;
                        background-size: auto 100%;
                    }

                    &.active3 {
                        background: url("../../assets/img/level3.png") no-repeat left center;
                        background-size: auto 100%;
                    }

                    p {
                        color: #3b77e7;

                        &.active {
                            color: red;
                        }
                        &.replied {
                            color: #795da3;
                        }
                        &.green {
                            color: green;
                        }

                        .el-dropdown {
                            position: absolute;
                            top: 0;
                            right: 0;
                            height: 26px;

                            img {
                                display: block;
                                width: 26px;
                                height: 26px;
                            }
                        }
                    }
                }

                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }
</style>
